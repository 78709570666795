<!--
  PACKAGE_NAME : src\pages\ai\llm\work-config
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2024-09-13
  DESCRIPTION : 운영관리 > LLM > LLM Tester > 프로젝트 작업 설정
-->
<template>
  <div class="container">
    <div class="page-sub-box content-box mt-4">
      <div class=""></div>
      <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
        <Tab :title="this.$_msgContents('LLM.WORD.SYSTEM_PROMPT', {defaultValue : '시스템 프롬프트'})">
          <SystemPrompt ref="systemPromptRef"/>
        </Tab>
        <Tab title="인덱스 설정">
          <IndexConfig ref="indexConfigRef"/>
        </Tab>
        <Tab title="질의 설정">
          <QuestionConfig ref="QuestionConfigRef"/>
        </Tab>
        <Tab title="모델 설정">
          <ModelConfig ref="modelConfigRef"/>
        </Tab>
        <Tab :title="this.$_msgContents('LLM.WORD.EVALUATION_PROMPT', {defaultValue : '평가 프롬프트'})">
          <EvaluationPrompt ref="evaluationPromptRef"/>
        </Tab>
      </Tabs>
    </div>

    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton
              :text="this.$_msgContents('LLM.WORD.RUNNING_TEST', {defaultValue : '테스트 실행'})"
              :width="120"
              :height="40"
              class="default filled txt_S medium"
              :use-submit-behavior="true"
              @click="onRunningTest"
          />
          <DxButton
              :text="this.$_msgContents('LLM.WORD.GO_TO_LIST', {defaultValue : '목록 가기'})"
              :width="120"
              :height="40"
              class="white filled txt_S medium"
              @click="onClickGoToList"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import SystemPrompt from '@/pages/ai/llm/work-config/system-prompt.vue';
import IndexConfig from '@/pages/ai/llm/work-config/index-config.vue';
import QuestionConfig from '@/pages/ai/llm/work-config/question-config.vue';
import ModelConfig from '@/pages/ai/llm/work-config/model-config.vue';
import EvaluationPrompt from '@/pages/ai/llm/work-config/evaluation-prompt.vue';

export default {
  components: {
    DxButton,
    Tabs,
    Tab,
    SystemPrompt,
    IndexConfig,
    QuestionConfig,
    ModelConfig,
    EvaluationPrompt,
  },

  data() {
    return {
      tabIndex: 0
    };
  },

  computed: {},

  watch: {
    /*tabIndex(index) {
      this.$nextTick(() => {
        switch (index) {
          case 2:
            this.$refs.QuestionConfigRef.setGridHeight();
            break;
          case 3:
            this.$refs.modelConfigRef.setGridHeight();
            break;
        }
      });
    }*/
  },

  methods: {
    /** @description 탭 클릭시 저장값 체크및 index 저장 */
    async tabSelectedIndex(index) {
      if(index !== 0) {
        if(this.$refs.systemPromptRef.isChangeContent){
          if(await this.$_Confirm(this.$_msgContents('LLM.MESSAGE.UNSAVED_PROMPT_MOVE_ALERT', {defaultValue: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>이동 하시겠습니까?'}))) {
            this.$refs.systemPromptRef.selectedPrompt.content = this.$refs.systemPromptRef.originContent;
            this.$refs.systemPromptRef.isChangeContent = false;
          } else {
            this.$refs.tabs.selectTab(0);
          }
        }
      }

      if(index !== 4) {
        if(this.$refs.evaluationPromptRef.isChangeContent){
          if(await this.$_Confirm(this.$_msgContents('LLM.MESSAGE.UNSAVED_PROMPT_MOVE_ALERT', {defaultValue: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>이동 하시겠습니까?'}))) {
            this.$refs.evaluationPromptRef.selectedPrompt.content = this.$refs.evaluationPromptRef.originContent;
            this.$refs.evaluationPromptRef.isChangeContent = false;
          } else {
            this.$refs.tabs.selectTab(4);
          }
        }
      }
      this.tabIndex = index;
    },

    /** @description 테스트 실행 클릭 */
    onRunningTest() {
      this.$_Toast("테스트 실행 미구현");
    },

    /** @description 목록 가기 클릭 */
    onClickGoToList() {
      this.$router.push('/ai/llm/list');
    },
  },

  created() {
  },

  mounted() {
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ecs-large-tab {
    display: flex;
  }

  .ecs-large-tab > ul > li:first-child {
    margin: 0;
  }

  .ecs-large-tab > ul {
    flex-direction: column;
    text-align: center;
  }

  .ecs-large-tab > ul > li {
    font-size: 14px !important;
    width: 100% !important;
    text-align: center !important;
    margin: 0 20px 0 0 !important;
  }

  .ecs-large-tab > ul > li::after {
    width: 5px;
    height: 100% !important;
    left: unset;
    right: 0;
    background-color: unset;
  }

  .ecs-tab-box {
    margin: 10px 0 0 0 !important;
  }

  .page-sub-box {
    width: 100% !important;
  }

  .dx-datagrid-header-panel {
    padding-top: 0;
  }
}
</style>
